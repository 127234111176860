.business-benefits-line {
  padding: 60px 20px;
  background-color: #1b1b1b;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 60px;  /* Increase gap between benefit rows */
}

.benefit-row {
  display: flex;
  align-items: top; /* Align icons and text horizontally */
  justify-content: center; /* Center content horizontally */
  gap: 40px;  /* Space between icons and text */
  margin: 0px 20px;  /* Adjust margins to reduce excessive space on mobile */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.icon-line {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;  /* Adjust spacing on smaller screens */
}

.benefit-icon {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
  animation: pulse 2s infinite;
}

.line {
  width: 2px;
  background-color: #ff6b6b;
  height: 100px;
  opacity: 0;
  transition: opacity 1s ease;
}

.benefit-text-block {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
  text-align: left;  /* Keep text aligned left */
  max-width: 600px;  /* Control text width */
  padding-left: 60px; /* Space between icons and text */
}

.benefit-text-block.in-view,
.icon-line .benefit-icon.in-view,
.icon-line .line.in-view {
  opacity: 1;
  transform: translateY(0);
}

.benefit-text-block h3 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  margin-block-start: 0;
}

.benefit-text-block p {
  font-size: 1.2rem;
  color: #ddd;
}

@media (max-width: 768px) {
  .surveillance-benefits-line {
    padding: 30px 10px; /* Reduce padding on mobile */
  }

  .benefit-row {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Keep text and icons aligned left */
    gap: 20px; /* Reduce gap between icons and text on mobile */
    margin: 0px 10px;  /* Reduce margins on mobile */
  }

  .icon-line {
    flex-direction: row; /* Keep icons and lines horizontal on mobile */
    margin-bottom: 0;
    margin-right: 10px;
  }

  .benefit-icon {
    font-size: 3rem; /* Reduce icon size on mobile */
    margin-bottom: 0; /* Remove margin on mobile */
  }

  .line {
    height: 50px; /* Reduce line height on mobile */
  }

  .benefit-text-block {
    max-width: 100%; /* Allow text to take full width */
    padding-left: 20px; /* Reduce padding on mobile */
  }

  .benefit-text-block h3 {
    font-size: 1.8rem; /* Reduce heading size on mobile */
  }

  .benefit-text-block p {
    font-size: 1rem; /* Reduce paragraph size on mobile */
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
