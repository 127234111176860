.footer {
  color: white;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #292929; /* Slightly darker background */
  text-align: center;
}

.footer p {
  color: #fff;
  margin-right: 30px;
}

.social-media {
  list-style: none;
  padding: 0;
  margin-left: 20px;
}

.social-media a {
  margin: 0 10px;
  color: white;
  font-size: 24px;
  text-decoration: none;
}

.social-media a:hover {
  color: #4CAF50;
  text-decoration: underline;
}