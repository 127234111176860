.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.pricing-header {
  font-size: 3rem;
  color: #ff3c45;
  margin-bottom: 1rem;
}

.pricing-subtext {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 3rem;
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  margin: 1rem;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
}

h2 {
  font-size: 2rem;
  color: #333;
}

p {
  font-size: 1.25rem;
  color: #666;
  margin: 0.5rem 0;
}

.cta-button {
  background-color: #ff3c45;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #ff202a;
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }
}

/* PricingPage.css */

.pricing-container {
  background-color: #f5f5f5;
  padding: 60px 20px;
  text-align: center;
}

.pricing-title {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #333;
  animation: fadeIn 1s ease-out;
}

.pricing-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.pricing-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 300px;
  margin: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: slideIn 0.5s ease-out;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.card-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.card-description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

.card-limit {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
