.product-demo h2 {
    font-size: 2.5rem; /* Larger font size */
}

.product-demo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.steps {
  width: 150%;
}

.step-box {
  background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Modern gradient */
  color: white; /* White text for contrast */
  font-size: 1.7rem; /* Larger font size */
  padding: 20px;
  border-radius: 12px; /* Smooth corners */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow for depth */
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
}

/* Hover effect on step boxes */
.step-box:hover {
  transform: translateY(-5px); /* Adds movement when hovered */
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.3);
}

/* Text in the step box */
.step-text {

}

/*.step-box:hover {
  transform: scale(1.05);
}*/


.demo-video {
  width: 55%;
}

.demo-video {
  width: 100%;
  object-fit: cover; /* Ensure video fits within the container */
}

.demo-video video {
  max-height: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .product-demo-container {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .steps, .demo-video {
    width: 100%;
  }

  .demo-video video {
    max-height: 500px;
  }
}
