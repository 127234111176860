/* BlogPost.css */
.blog-post-container {
  width: 80vw;
  margin: 0 auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background-color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.blog-post-container img {
  width: 70%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.blog-header {
  background-color: #fff;
  color: #fff;
  padding: 3rem;
  width: 100%;
  box-sizing: border-box;
}

.blog-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #000;
}

.blog-meta {
  color: #ff3c45;
  font-size: 1.125rem;
}

.blog-meta .separator {
  margin: 0 0.5rem;
  color: #666;
}

.blog-content {
  padding: 3rem;
  font-size: 1rem;
  line-height: 1.7;
  color: #D3D3D3;
}

.blog-content h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: #D3D3D3;
}

.blog-content p {
  margin-bottom: 1.5rem;
  color: #D3D3D3;
}

.blog-content ul {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
}

.blog-content li {
  margin-bottom: 0.75rem;
  color: #D3D3D3;
  text-align: left;
}

.blog-post p {
  font-size: 1.1rem;
  color: #D3D3D3;
  margin: 0.5rem 0;
}

.blog-post a {
  color: #D3D3D3;
}

.blog-footer {
  padding: 2rem 3rem;
  border-top: 1px solid #eee;
}

.back-link {
  color: #666;
  text-decoration: none;
  font-weight: 500;
}

.back-link:hover {
  color: #333;
}

.blog-post-loading,
.blog-post-error {
  text-align: center;
  padding: 3rem;
  color: #666;
  font-size: 1.125rem;
}

.blog-post-error {
  color: #dc2626;
}

@media (max-width: 768px) {
  .blog-header,
  .blog-content,
  .blog-footer {
    padding: 2rem;
  }

  .blog-title {
    font-size: 2.5rem;
  }

  .blog-content {
    font-size: 1.1rem;
  }

}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-break: keep-all; /* Prevents text from breaking awkwardly */
}


.footnotes {
    align-items: flex-start;
    padding: 0px;
    text-align: left;
    justify-content: left;
}

@media (max-width: 768px) {
  .blog-post-container {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    display: block;  /* Make table scrollable */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: prewrap;
  }


  .blog-post-container img {
    width: 100%;
  }

  .footnotes {
    max-width: 100%;
    width: 100%; /* Ensures it takes the full available width */
    overflow-wrap: break-word; /* Breaks long words */
    word-wrap: break-word; /* Ensures text wrapping */
    white-space: normal; /* Allows text wrapping */
    text-align: left;
    display: block; /* Ensures it behaves as a block element */
    overflow-x: auto; /* Enables horizontal scrolling if necessary */
  }
}