.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292929;
  padding: 1rem 2rem;
  height: 70px;
  position: relative;
}

.navbar .logo {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.navbar .logo img {
  height: 40px;
  margin-top: 15px;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0
}

.nav-item {
  margin-right: 40px;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.nav-item:hover .dropdown {
  display: block;
}

.dropdown {
  display: none;
  position: absolute;
  top: 40px;
  background-color: #444;
  list-style: none;
  padding: 10px 0;
  border-radius: 8px;
  min-width: 200px;
  z-index: 1;
}

.dropdown li {
  padding: 12px 16px;
  color: #fff;
  display: block;
  align-items: center;
  text-decoration: none;
}

.dropdown li:hover {
  background-color: #555;
}

.hamburger-icon {
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  z-index: 1001;
}

.desktop-menu {
  display: flex;
}

.mobile-navigation {
  display: none;
  position: right;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding-top: 1rem;
}

.mobile-menu .nav-links {
  flex-direction: column;
  align-items: center;
}

.mobile-menu .nav-item {
  margin: 20px 0;
}

.mobile-menu .nav-item a {
  font-size: 24px;
}

.mobile-menu .action-buttons a {
  display: inline-block; /* Prevent buttons from stacking */
  margin: 0.5rem; /* Add space between buttons */
  padding: 0.5rem 1rem;
  color: #292929;
  text-decoration: none;
  border-radius: 5px;
}

.book-demo {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .mobile-navigation {
    display: flex;
  }

  .desktop-menu {
    display: none;
  }

  .navbar {
    padding: 0.2rem 0.5rem;
    height: auto;
  }

  .navbar .logo {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px; /* Slightly smaller font size */
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.5rem; /* Add some space below the logo */
  }

  .navbar .logo img {
    height: 30px; /* Adjust logo size for smaller screens */
  }

  .navbar ul {
    flex-direction: column;
  }

  .hero h1 {
    font-size: 48px;
  }

  .hero p {
    font-size: 18px;
  }
}

.footer {
  margin-top: auto;
  padding: 20px;
  text-align: center;
  color: #fff;
}
