.get-in-touch {
  padding: 100px 50px;
  max-width: 700px;
  margin: 50px auto;
  text-align: center;
  background-color: #1a1a1a;
  border-radius: 12px;
  color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: relative;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInDown 0.8s ease forwards;
}

.form-container {
  width: 100%;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1.2s ease forwards;
}

.get-in-touch-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.form-group {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px 0px;
  margin: 0;
  border: 2px solid #ff3c45;
  border-radius: 8px;
  background: #292929;
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  box-shadow: 0 0 8px rgba(255, 60, 69, 0.8);
  outline: none;
}

.form-group label {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 1rem;
  color: #888;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 0.9rem;
  color: #ff3c45;
}

textarea {
  height: 120px;
  resize: none;
}

.submit-button {
  background-color: #ff3c45;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #e62e36;
  transform: scale(1.05);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

