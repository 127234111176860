.blog-section {
  padding: 80px 0;
  color: white;
  text-align: left;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
}

.blog-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for desktop */
  gap: 30px;
  justify-content: center;
  max-width: 1200px; /* Keeps the layout centered and constrained */
  margin: 0 auto;
}

.blog-card {
  background-color: #292b2c;
  border-radius: 15px;
  overflow: hidden; /* Ensures image and content stay inside the box */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Make all cards equal in height */
  margin: 1rem;
}

.blog-card img.blog-image {
  width: 100%;
  height: 200px; /* Fixed height for images */
  object-fit: cover; /* Ensures the image doesn’t get distorted */
}

.blog-content {
  padding: 20px; /* Adds space around the content */
  display: flex;
  flex-direction: column;
  text-align: left;
}

.blog-card h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #fff;
}

.blog-card p {
  font-size: 1rem;
  color: #D3D3D3;
}

.blog-link {
  margin-top: 20px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  color: #ff3c45; /* Matches the feature icon color */
  transition: color 0.3s;
}

.blog-link:hover {
  color: #ff6565; /* Slightly lighter hover effect */
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .blog-cards-container {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
}

.markdown-content {
  padding: 1rem;
  max-width: 100%;
  overflow-wrap: break-word;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
}