.hero {
  height: 80vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.product-section, .how-it-works, .motivation {
  padding: 50px;
}

.cta-section {
  padding: 20px;
  text-align: center;
}

section {
  padding: 200px 0;
  /*background-color: #1f1f1f; /* Dark grey background */
  text-align: center;
}

#product, #how-it-works, #why-us {
  padding: 120px 0;
  width: 100%;
}

.hero h1 {
  font-size: 64px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.hero p {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 50px;
}

.product-content {
  width: 100%
}

section {
  /*height: 100vh;*/
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature-header {
  font-size: 3rem;
  margin-bottom: 60px;
  color: #fff;
  font-weight: bold;
  /*text-transform: uppercase;*/
}

@media (max-width: 768px) {
  .feature-header {
    font-size: 2.5rem;
  }
}