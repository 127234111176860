.action-buttons .demo-btn,
.action-buttons .playground-btn {
  background-color: #ff3c45;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.action-buttons .demo-btn:hover,
.action-buttons .playground-btn:hover {
  background-color: #155A8A;
}

@media (max-width: 768px) {
    .action-buttons {
      display: flex;
      flex-direction: column; /* Stack buttons vertically on small screens */
      align-items: center;
    }

    .action-buttons .demo-btn,
    .action-buttons .playground-btn {
      background-color: #ff3c45;
      color: white;
      padding: 8px 16px; /* Adjust padding for smaller screens */
      border: none;
      border-radius: 20px;
      font-size: 24px; /* Slightly smaller font size */
      margin-bottom: 10px; /* Space between buttons */
      cursor: pointer;
    }

    .action-buttons .demo-btn:hover,
    .action-buttons .playground-btn:hover {
      background-color: #155A8A;
    }
}