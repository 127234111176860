.product-features {
  padding: 80px 0;
  color: white;
  text-align: center;
}

.feature-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  gap: 30px;
  justify-content: center;
}

/* This is added to center the card on last row */
.feature-card:last-child:nth-last-child(3n-2) {
  grid-column: 2;
}


.feature-card {
  background-color: #292b2c;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
}

.feature-icon {
  font-size: 2.5rem;
  color: #ff3c45;
}

.feature-card h3 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff;
}

.feature-card p {
  font-size: 1rem;
  color: #ccc;
}

.reveal {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.reveal.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .feature-cards-container {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }

  .feature-card:last-child:nth-last-child(3n-2) {
    grid-column: 1;
  }
}
