.github-banner {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #24292f; /* GitHub dark gray */
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.github-banner:hover {
  transform: scale(1.05); /* Slight grow effect on hover */
  background-color: #0366d6; /* GitHub blue */
}

.github-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.github-link:hover {
  text-decoration: underline;
}

.github-link::before {
  content: '⭐';
  margin-right: 8px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .github-banner {
    font-size: 14px;
    padding: 8px 16px;
  }
}
