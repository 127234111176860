/* Add these styles in your CSS file */
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px; /* Increased base font size */
  color: #fff;
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eae4);
  background-size: 800% 800%;
  animation: gradientAnimation 15s ease infinite;
}

header {
  align-items: center;
  padding: 10px 10px; /* Smaller padding */
  background-color: #292929;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}
